<template>  
    <main>
        <v-row>
          <v-col cols="7" class="fields test">
            <v-text-field label="URL" v-model="url" dense dark outlined />
            <v-text-field label="PATH" v-model="path" dense outlined />
            <v-textarea label="JWT token" v-model="jwttoken" dense outlined />
            <v-btn @click="connect()">
                CONNECT
            </v-btn>
            <v-btn @click="disconnect()">
                DISCONNECT
            </v-btn>
          </v-col>
          
        </v-row>
    </main>
</template>

<script>

export default {

  data() {
    return {
      url: null,
      path: null,
      jwttoken: null,
      socket: null
    };
  },
  methods: {
    receiveMessage (payload) {
      console.log('received a message', payload)
    },
    connect(){
      console.log('Init connect...');
    
      this.socket = new WebSocket(this.url, [this.jwttoken])

      this.socket.onopen = function(event) {
        console.log(event)
        console.log("Successfully connected to the echo websocket server...")
      }
      this.socket.onmessage = this.receiveMessage
      /*this.socket = io(this.url, {
        path: this.path,
        transports: ['websocket'],
        extraHeaders: {
          'Authorization': `Bearer ${this.jwttoken}`
        }
      });
      this.socket.on('connect', () => {
        console.log(this.socket.connected);
      });
      this.socket.on('message', this.receiveMessage);*/
    },
    disconnect(){
      console.log('disconnect');
      this.socket.close();
    }
  }
};
</script>

<style lang="scss" scoped>
.test {
  padding: 30px 30px 0 30px;
}
.pod-page header {
  padding: 1rem;
  position: absolute;
}

.pod-page aside {
  position: absolute;
  left:3%;
  top: 15%;
}

.local-video {
  position: absolute;
  bottom: 100px;
  right: 15px;

  width: 250px;
  height: 180px;

  z-index: 10;
}

.remote-video {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;

  place-content: stretch;
  place-items: stretch;

  width: calc(100% - 80px);
  height: 100%;

  margin-left: 80px;

  & > * {
    max-width: 40%;
    max-height: 50%;

    min-width: 250px;
    min-height: 180px;

    padding: 1rem;
  }
}
</style>
